import { FC, useEffect } from 'react';

import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import {
    Switch,
    Route,
    useRouteMatch,
    useParams,
    withRouter
} from "react-router-dom";

import { fetchCandidateList, fetchCandidateDocuments, setCandidateIdentifier } from './../redux-building-blocks/actions';

import CandidateList from './Students/candidate-list';
import RationaleDocument from './Students/rationale-document';
import AdditionalMaterial from './Students/additional-material';
import CandidateEvidence from './Students/candidate-evidence';

const Syllabus: FC = (props: any): JSX.Element => {
    const { id }: any = useParams();
    let { path } = useRouteMatch();

    useEffect(() => {
        if (!isEmpty(props.syllabusList)) {
            props.fetchCandidateList(id);
            props.fetchCandidateDocuments(id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.syllabusList, path]);
    return (
        <Switch>
            <Route exact path={`/syllabus/${id}`} component={CandidateList} />
            <Route path={`${path}/candidate-evidence/:candidateId`} component={CandidateEvidence} />
            <Route path={`${path}/rationale-document`} component={RationaleDocument} />
            <Route path={`${path}/additional-document`} component={AdditionalMaterial} />
        </Switch>
    )
}

const mapStateToProps = (state: any) => {
    return {
        syllabusList: state.setContextData.syllabusList
    }
}

const mapDispatchToProps = {
    fetchCandidateList,
    fetchCandidateDocuments,
    setCandidateIdentifier
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Syllabus));