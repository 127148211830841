import jwt_decode from "jwt-decode";
import _ from 'lodash';
import { decode } from 'js-base64';

class JwtHelperClass {
  static jwtToken: string | null = null;
  static getJwtToken() {
    return sessionStorage.getItem('jwtToken');
  }

  static hideToken() {
    const currentURL = window.location.href;
    if (currentURL.split('?')[1]) {
      window.location.href = currentURL.split('?')[0]
    }
  }

  static setJwtToken(token: string) {
    this.jwtToken = token;
    sessionStorage.setItem('jwtToken', token);
  }

  static readUrlParamValue(paramName: string): string | null {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(paramName);
  }

  static getDecodedToken() {
    const decodedJwt = (this.jwtToken) ? jwt_decode(this.jwtToken as string) : null;
    return decodedJwt;
  }

  static getEncodedIdStringFromJWT() {
    const decodedJwt = this.getDecodedToken();
    let idString = null;
    if (decodedJwt) {
      const idFullString = _.nth(_.get(decodedJwt, 'amr', []), 2);
      if (idFullString) {
        const encodedIdString = _.last(_.split(idFullString as string, ':'));
        if (!_.isEmpty(encodedIdString)) {
          idString = encodedIdString as string;
        }
      }
    }
    return idString;
  }

  static getDecodedIdStringFromJWT() {
    let idString = null;
    const encodedIdString = this.getEncodedIdStringFromJWT();
    if (!_.isEmpty(encodedIdString)) {
      idString = decode(encodedIdString as string);
    }
    return idString;
  }

  static getIdFromToken(position: number) {
    const decodedIdString = this.getEncodedIdStringFromJWT();
    let strId = null;
    if (!_.isEmpty(decodedIdString)) {
      strId = _.nth(_.split(decodedIdString as string, /[_|-]+/), position);
    }
    return strId;
  }

  static getCentreIdFromToken() {
    return this.getIdFromToken(1);
  }

  static getUserIdFromToken() {
    return this.getIdFromToken(2);
  }

  static jwtTokenResetInStorage() {
    this.jwtToken = null;
    sessionStorage.removeItem('jwtToken');
  }
}

export default JwtHelperClass;

