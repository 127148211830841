import { FC, Fragment, useEffect, useState } from 'react';
import CandidateInstructions from '../Students/candidate-instructions';
import { connect } from 'react-redux';
import {
    Col,
    Row,
    CardBody,
    Card,
    Container,
    CardText
} from 'reactstrap';
import { useHistory, useRouteMatch } from "react-router-dom";
import { fetchCandidateList, setCandidateIdentifier, fetchCandidateDocuments, fetchEvidenceLevelFilesFailure } from '../../redux-building-blocks/actions';
import _ from 'lodash';
import { Constant } from '../../config/constant';

const fetchRationalDocCount = (docs: any) => {
    let rationalCount = 0;
    if (docs.rationaledocumentname && docs?.rationaledocumentname !== "NONE" && docs?.rationaledocumentname !== "") {
        rationalCount++;
    }
    return rationalCount
}
const CandidateList: FC = (props: any): JSX.Element => {
    let { url } = useRouteMatch();
    const [docCountRational, setRationalDocCount] = useState(0);
    const [docCountAdditional, setDocCountAdditional] = useState(0);

    useEffect(() => {
        if (_.has(props, 'candidateRationalDocuments.rationaledocumentname')) {
            setRationalDocCount(fetchRationalDocCount(props.candidateRationalDocuments))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.candidateRationalDocuments])

    useEffect(() => {
        if (_.has(props, 'candidateAdditionalDocuments')) {
            setDocCountAdditional(props.candidateAdditionalDocuments.length)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.candidateAdditionalDocuments])

    const history = useHistory();
    const candidateEvidenceList = (identifier: string) => {
        // props.setCandidateIdentifier(identifier);
        history.push({
            pathname: `${url}/candidate-evidence/${identifier}`,
            state: {
                fromPath: props.location.pathname
            }
        });
    }
    const visitRationaleDocument = () => {
        props.fetchEvidenceLevelFilesFailure();
        history.push({
            pathname: `${url}/rationale-document`,
            state: {
                fromPath: props.location.pathname
            }
        });
    }

    const visitAdditionalMaterial = () => {
        props.fetchEvidenceLevelFilesFailure();
        history.push({
            pathname: `${url}/additional-document`,
            state: {
                fromPath: props.location.pathname
            }
        });
    }

    return (
        <Fragment>
            <CandidateInstructions
                centreId={props.centreId}
                syllabusNumber={props.selectedSyllabus?.syllabusNumber}
                syllabusName={props.selectedSyllabus?.syllabusName}
                candidateCount={props.selectedSyllabus?.candidateDetails?.length} />
            {props.selectedSyllabus?.candidateDetails && <Container className="candidate-list-container">
                <Card className="card-row mb-2">
                    <CardBody className="card-body pxy-2 hoverRow" onClick={() => visitRationaleDocument()}>
                        <Row className="align-items-center">
                            <Col md={8}>
                                <div className="h5 font-weight-bold centerName">Rationale document</div>
                                <div className="font-weight-500 grayText f18">Mandatory</div>
                            </Col>
                            <Col md={4}>
                                <span className="file-count">{docCountRational} file(s) uploaded</span>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Card className="card-row mb-2">
                    <CardBody className="card-body pxy-2 hoverRow" onClick={() => visitAdditionalMaterial()}>
                        <Row className="align-items-center">
                            <Col md={8}>
                                <h3 className="h5 font-weight-bold centerName">Additional material</h3>
                                <div className="font-weight-500 grayText f18">Optional</div>
                            </Col>
                            <Col md={4}>
                                <span className="file-count">{docCountAdditional} file(s) uploaded</span>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                {props.selectedSyllabus?.candidateDetails && props.selectedSyllabus?.candidateDetails.map((value: any, index: number) => {
                    return (
                        <Card className="card-row mb-2" key={index}>
                            <CardBody className="card-body pxy-2">
                                <Row className="align-items-center">
                                    <Col md={8}>
                                        <h3 className="h5 font-weight-bold centerName">{value.candidateName}</h3>
                                        <div className="font-weight-500  f18">{value.candidateUserNumber}</div>
                                        {value?.isDuplicate && props.syllabusStatus === Constant.SYLLABUS_STATUS_MESSAGE_FROM_API.SECOND_SAMPLE_SUBMITTED  && <div className="font-weight-500 grayText f18">Sample {value.setNumber}</div>}
                                        {/* {value?.isDuplicate && (props.syllabusStatus === Constant.SYLLABUS_STATUS_MESSAGE_FROM_API.SECOND_SAMPLE_SUBMITTED || props.syllabusStatus === Constant.SYLLABUS_STATUS_MESSAGE_FROM_API.SECOND_SAMPLE_REQUESTED) && <div className="font-weight-500 grayText f18">Sample {value.setNumber}</div>} */}
                                    </Col>
                                    <Col md={1}>
                                        {/* <span className="file-count">{value.uploadedFiles} file(s) uploaded</span> */}
                                    </Col>
                                    <Col md={3}>
                                        <button className="btn statusBtn" onClick={() => candidateEvidenceList(value.candidateNumber)} >
                                            <span className="btn-status-text">Access Evidence Folders</span>
                                        </button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    )
                })}
            </Container>}
            {(!props.selectedSyllabus?.candidateDetails || props.selectedSyllabus?.candidateDetails.length <= 0) && <Card body className="text-center rounded">
                <CardText>No related Candidate to display for this Syllabus</CardText>
            </Card>
            }
        </Fragment>
    )
}

const mapStateToProps = (state: any) => {
    return {
        selectedSyllabus: state.setContextData.selectedSyllabus,
        centreId: state.setContextData.currentCentreId,
        candidateRationalDocuments: state.setContextData.candidateGenericDocuments,
        candidateAdditionalDocuments: state.setContextData.candidateAdditionalDocuments,
        syllabusStatus: state.setContextData?.selectedSyllabus?.syllabusStatus
    }
}

const mapDispatchToProps = {
    fetchCandidateList,
    setCandidateIdentifier,
    fetchCandidateDocuments,
    fetchEvidenceLevelFilesFailure
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateList);