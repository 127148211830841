import React, { FC, useState,useEffect } from 'react';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload, faPaperPlane, faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  Col,
  Row
} from 'reactstrap';
import moment from 'moment';
import { SupmitPopup } from '../Shared/Modal/submit-modal';
import { downloadSAGFile, toggleAlertShowStatus, evidenceSubmission } from '../../redux-building-blocks/actions';
import _ from 'lodash';
import { getCurrentSyllabusStayus } from './../../redux-building-blocks/selectors/util-selector'

interface ICandidateInstructions {
  centreId: string;
  syllabusNumber: string;
  syllabusName: string;
  candidateCount: any
}

const CandidateInstructions: FC<ICandidateInstructions> = (props: any): JSX.Element => {
  const [showModal, setShow] = useState(false);
  const [confirmDeclaration, setConfirmDeclaration] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    setConfirmDeclaration(false);
  }
  const [docCountRational, getRationalDocCount] = useState(0);
  const downloadGrades = () => {
    if (props.syllabusGradeFile !== 'NONE' && props.syllabusNumber) {
      props.downloadSAGFile(props.syllabusNumber, props.syllabusGradeFile)
    } else {
      props.toggleAlertShowStatus({
        isShowAlert: true,
        alertColor: 'info',
        alertMessage: `No Grades files present.`
      })
    }
  }
  const handleCheckboxChange = (event:any) =>{
    setConfirmDeclaration(event.target.checked);
  }
   
  const evidenceSubmission = () =>{
    const evidenceSubmissionPayload = {
      syllabus: props.syllabusNumber
  };
    props.evidenceSubmission(evidenceSubmissionPayload);
    setShow(false);
  }

  useEffect(() => {
    if(_.has(props, 'candidateRationalDocuments.rationaledocumentname')) {
        getRationalDocCount(fetchRationalDocCount(props.candidateRationalDocuments))
    }
}, [props.candidateRationalDocuments])

const fetchRationalDocCount = (docs: any) => {
  let rationalCount = 0;
  if (docs.rationaledocumentname && docs?.rationaledocumentname !== "NONE" && docs?.rationaledocumentname !== "") {
      rationalCount++;
  }
  return rationalCount
}

  return (
    <>
      <Container fluid className='container-wrapper'>
        <div className='wrapper'>
          <div>
            <p className='theme-label centre-head'>Centre {props.centreId}-{props.syllabusName}</p>
            <p className='syllabus-subtext'><span className='igcse-label f20'>{props.syllabusNumber}</span> | <span className='candidate-number f18'>{props.candidateCount} {props.candidateCount === 1 ? 'candidate' : 'candidates'}</span></p>
          </div>
          <div className='align-right'>
            <button className='download-SAG' onClick={() => downloadGrades()}>
              <FontAwesomeIcon icon={faFileDownload} /><br />
              <span>Download school-assessed grades</span>
            </button>
            <button className='submit-all' onClick={() => handleShow()} disabled={props.isSubmited}>
              <FontAwesomeIcon icon={faPaperPlane} /><br />
              <span>Submit</span></button>
          </div>
          <div>
            <Row>  
            <Col>
            { props.isSubmited && (
            <div className="font-weight-500">This is a view only mode</div>
            )}
              <div className='student-instruction'>
              Select a candidate to view their evidence files and grades. To upload a rationale document select the 'Rationale Document' category at the top of the list below. You can also upload documents that are generic to all candidates by selecting Additional material. Once you have uploaded all the necessary files and grades, click Submit. Once submitted, you cannot edit grades or delete files.
                   </div>
            </Col>
            <Col>
            <div className='student-instruction pt-4'>
           <label className='bold-text'>Please Note:</label> Every file uploaded will be checked for viruses. After uploading, you will see the results of the check after a few seconds. Please wait up to 5 minutes after uploading a file larger than 2GB before trying to upload another file. Upload speed will depend on the size of the file and quality of your internet connection. 
           </div>
            </Col>
            </Row>
          </div>
        </div>
      </Container>
      {showModal && (
        <SupmitPopup
          className="bg-white"
          isOpen={showModal}>
          <Row className="pb-3">
            <Col>
            <div className="font-weight-bold centerName">{props.syllabusName}</div>
              <div className="font-weight-bold grayText"><span className='igcse-label'>{props.syllabusNumber}</span> | <span className='candidate-number'>{props.candidateCount} {props.candidateCount === 1 ? 'candidate' : 'candidates'}</span></div>
            </Col>
            <FontAwesomeIcon icon={faTimes} className="grayText cancelModal" onClick={() => handleClose()} />
          </Row>
          { docCountRational===0 && (
          <div className="warningText">
            <p className="font-weight-bold pt-3">
              Please note that Rationale document is not uploaded!
            </p>
           </div>
          )}
          <p className="font-weight-bold pt-3">Submit grades and evidence on the Quality Assurance portal</p>
          <p>Submitted grades and evidence cannot be changed.</p>
          <p>
          If you do not want to submit these grades and evidence now, press 'Cancel'. The grades and 
          evidence will not be submitted and you will return to the previous page.
          </p>
          <p>
          If you do want to submit these grades and evidence, please complete the declaration by ticking 
          below and pressing the submit button.
          </p>
          <p className="font-weight-bold">Declaration</p>
          <ul>
          <p><li>I confirm that, to the best of my knowledge, the work submitted is solely that of the 
              candidates entered and was conducted under the conditions required for the 
              Cambridge International <a  style={{ textDecoration: "underline", color:  "#0274B6" }} target="_blank" rel="noopener noreferrer" href="https://www.cambridgeinternational.org/covid/june-2021-exam-series/school-assessed-grades/setting-school-assessed-grades/">school-assessed grades process</a>.
          </li></p>
            <p>
              <li>
              I confirm that the grades and evidence I am submitting have been internally standardised/moderated where relevant.
          </li></p>
          <p>
              <li>
              I confirm that the grades and evidence I am submitting are an accurate representation of each candidate’s demonstrated performance in the syllabus sampled. 
          </li></p>
          </ul>
          <Row className="px-4">
            <input type="checkbox" className="customCheckbox" id="customCheckbox" onChange={(e)=>handleCheckboxChange(e)}/>
            <label className="font-weight-bold pl-3 w-75" htmlFor="customCheckbox">I confirm that the data submitted is final and correct. I agree to the Declaration of Authority 
            on behalf of my centre.</label>
          </Row>
          <Row className="px-5 pt-4">
            <Col>
              <div className="font-weight-bold">Date authorised</div>
              <div className="font-weight-bold grayText">{moment().format("DD-MM-YYYY")} ({moment().format("UTC HH:mm")})</div></Col>
            <button className="btn startBtn"  onClick={() => evidenceSubmission()} disabled={(confirmDeclaration===false || docCountRational===0)}>
              <span className="font-weight-bold">Submit</span>
            </button>
          </Row>
        </SupmitPopup>
      )}
    </>
  )
}
const mapStateToProps = (state: any) => {
  return {
    syllabusGradeFile: state.setContextData.candidateGenericDocuments?.syllabusgradesname,
    candidateRationalDocuments: state.setContextData.candidateGenericDocuments,
    isSubmited: getCurrentSyllabusStayus(state)
  }
}
const mapDispatchToProps = {
  downloadSAGFile,
  toggleAlertShowStatus,
  evidenceSubmission
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateInstructions);

