import APIService from '../../services/apiService';
import { setSyllabusList, fetchContextDataError } from '../actions';
import { call, put } from "redux-saga/effects";

interface IContextData {
    data: {
        data: []
    }
}


export default function* getContextData() {
    let res: IContextData;
    try {
        res = yield call([APIService, 'fetchContextData']);
        const syllabusStatusres: Array<any> = yield call([APIService, 'fetchSyllabusStatus']);
        yield put(setSyllabusList(res.data.data, syllabusStatusres));
    }
    catch {
        yield put(fetchContextDataError())
    }
}