import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from "react-router-dom";
import {
    Col,
    Row,
    CardBody,
    Card,
    CardText
} from 'reactstrap';


const SyllabusList: FC = (props: any): JSX.Element => {

    const history = useHistory();
    const visitCandidate = (index: number) => {
        history.push({
            pathname: `/syllabus/${props.syllabusDetails[index].syllabusNumber}`,
            state: {
                fromPath: props.location.pathname
            }
        });
    }

    return (
        <div className="container">
            <h3 className="font-weight-bold centerName mt-5 mb-3">Centre {props.centreId}</h3>
            <p className="mb-4">Select a syllabus to see the candidates entered.</p>
            {props.syllabusDetails && props.syllabusDetails.map((value: any, index: number) => { 
                return (
                    <Card className="card-row mb-2" key={index}>
                        <CardBody className="card-body pxy-2">
                            <Row className="align-items-center">
                                <Col>
                                    <h3 className="h5 font-weight-bold centerName">
                                        {value.syllabusName}</h3>
                                    <div className="font-weight-bold grayText f18">
                                        {value.syllabusNumber}
                                    </div>
                                </Col>
                                {/* <Col xs="auto" className="text-right">
                                    <button className={`btn statusBtn ${value.status === 'In Progress' ? 'border-theme' : ''}`}>
                                        <span className={`font-weight-bold px-3 ${value.status === 'In Progress' ? 'theme-label' : ''}`}>{value.status}</span>
                                    </button>
                                </Col> */}
                                <Col xs="auto" className="text-right">
                                    <button className="btn startBtn" onClick={() => visitCandidate(index)}>
                                        <span className="font-weight-bold mr-3">Access Candidates</span>
                                        <FontAwesomeIcon className="fileUpload" icon={faArrowRight} />
                                    </button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                );
            })}
            {(!props.syllabusDetails || !props.syllabusDetails.length) && <Card body className="text-center rounded">     
                 <CardText>No related Syllabus to display for this Centre</CardText>
               </Card>
            }
        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        syllabusDetails: state.setContextData.syllabusList,
        centreId: state.setContextData.currentCentreId
    }
}

export default connect(mapStateToProps, null)(SyllabusList);