import { takeLatest, takeEvery } from 'redux-saga/effects';
import getContextData from './fetchContextData';
import getCandidateDocuments from './fetchCandidateDocs';
import downloadSAGFile from './downloadSAGFile';
import candidateFoldersLocation from './candidateFoldersLocation';
import evidenceLevelFiles from './evidenceLevelFiles';
import updateCandidateGrades from './updateCandidateGrade';
import evidenceSubmission from './evidenceSubmission';

import {
    GET_CONTEXT_DATA,
    FETCH_CANDIDATE_DOCUMENTS,
    DOWNLOAD_SAG_FILE,
    FETCH_CANDIDATE_FOLDERS,
    FETCH_EVIDENCE_FILES,
    UPDATE_CANDIDATE_GRADE,
    EVIDENCE_SUBMISSION
} from '../action-types';


export default function* sagaWatcher() {
    yield takeLatest(GET_CONTEXT_DATA, getContextData);
    yield takeLatest(FETCH_CANDIDATE_DOCUMENTS, getCandidateDocuments);
    yield takeLatest(DOWNLOAD_SAG_FILE, downloadSAGFile);
    yield takeLatest(FETCH_CANDIDATE_FOLDERS, candidateFoldersLocation);
    yield takeEvery(FETCH_EVIDENCE_FILES, evidenceLevelFiles);
    yield takeLatest(UPDATE_CANDIDATE_GRADE, updateCandidateGrades);
    yield takeLatest(EVIDENCE_SUBMISSION,evidenceSubmission)
}