import { createSelector } from 'reselect';
import _ from 'lodash';

import { Constant } from './../../config/constant';

const selectedSyllabus: any = (state: any) => state.setContextData.selectedSyllabus;

export const getCurrentSyllabusStayus = createSelector(selectedSyllabus, (currentSyllabus: any) => {
    const submittedStatus = [
        _.toLower(Constant.SYLLABUS_STATUS_MESSAGE_FROM_API.FIRST_SAMPLE_FURTHER_SUBMITTED),
        _.toLower(Constant.SYLLABUS_STATUS_MESSAGE_FROM_API.FIRST_SAMPLE_SUBMITTED),
        _.toLower(Constant.SYLLABUS_STATUS_MESSAGE_FROM_API.SAMPLE_SUBMITTED),
        _.toLower(Constant.SYLLABUS_STATUS_MESSAGE_FROM_API.SECOND_SAMPLE_FURTHER_SUBMITTED),
        _.toLower(Constant.SYLLABUS_STATUS_MESSAGE_FROM_API.SECOND_SAMPLE_SUBMITTED)
    ];
    return (submittedStatus.indexOf(_.toLower(currentSyllabus?.syllabusStatus)) >= 0) ? true : false;
})

export const checkEvidenceStatusForSecondEvidence = createSelector(selectedSyllabus, (currentSyllabus: any) => {

    return currentSyllabus;
})