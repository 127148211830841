import APIService from '../../services/apiService';
import { fetchCandidateDocumentsError, fetchCandidateDocumentsSuccess, fetchAdditionalDocumentsSuccess } from '../actions';
import { call, put } from "redux-saga/effects";

interface IDocument {
    "rationaledocumentname": string;
    "syllabusgradesname": string;
    "secondrationaledocumentname": string;
    "rationaledocumentlocation": string;
    "syllabusgradeslocation": string;
    "secondrationaledocumentlocation": string;
}
interface ICandidateDocument {
    data: {
        data: IDocument[]
    }
}
interface IDocDetails {
    "file-name": string;
    "file-type": string,
    "read-only": boolean
}
interface IDocuments {
    'additonal-materials': IDocDetails
}
interface IAdditionalResponse {
    data: {
        data: IDocuments
    }
}

export default function* getContextData(action: any) {
    let res: ICandidateDocument;
    let additionalResponse: IAdditionalResponse;
    try {
        res = yield call([APIService, 'fetchCandidateDocuments'], action.payload);
        additionalResponse = yield call([APIService, 'fetchAdditionalDocuments'], action.payload);
        yield put(fetchCandidateDocumentsSuccess(res.data.data[0]));
        yield put(fetchAdditionalDocumentsSuccess(additionalResponse.data.data));
    }
    catch {
        yield put(fetchCandidateDocumentsError())
    }
}