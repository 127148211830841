import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder } from '@fortawesome/free-regular-svg-icons';
import _ from 'lodash';
import Uploader from './../Shared/Uploader/uploader';
import { Constant } from './../../config/constant';
import APIService from './../../services/apiService';
import { updateAdditionalDocument, toggleAlertShowStatus,
    fetchCandidateDocuments } from '../../redux-building-blocks/actions';

export const AdditionalMaterial = (props: any): JSX.Element => {
    const [uploadedFileList, setUploadedFileList] = useState<Array<string>>([]);

    const [uploaderProps, setUploaderProps] = useState({
        noOfMaxFile: 10,
        fileFormatAndSizeSupported: Constant.ADDITIONAL_DOCUMENT_ALLOWED_FILE_TYPE_SIZE
    });

    const handleUploadOrDeleteSuccess = (currentRelatedFiles: Array<string>, type: string, fileName = '') => {
        setUploadedFileList(currentRelatedFiles);
        const message = (type === 'UPLOAD') ? `The file ${fileName} will be scanned for Virus and only accepted if virus-free, else the file will be discarded.` : 
        `${fileName} is deleted`;
        props.updateAdditionalDocument(currentRelatedFiles);
        // props.fetchCandidateDocuments(props.selectedSyllabusId);
        props.toggleAlertShowStatus({
            isShowAlert: true,
            alertColor: 'success',
            alertMessage: message
        });

        // Making this call to refresh file list as virus scan may discard uploaded file
        if(type === 'UPLOAD') {
            setTimeout(() => {
                props.fetchCandidateDocuments(props.selectedSyllabusId);
            }, Constant.GET_CALL_TIME_AFTER_UPLOAD);
        }
    }

    const handleUploadOrDeleteError = (currentRelatedFiles: Array<string>, type: string) => {
        const message = (type === 'UPLOAD') ? `You can't upload ${currentRelatedFiles}, Due to network issue` :
            `You can't delete ${currentRelatedFiles}, Due to network issue`;
        props.toggleAlertShowStatus({
            isShowAlert: true,
            alertColor: 'danger',
            alertMessage: message
        })
    }


    const returnPresignedUploadURl = (filename: string) => {
        const payload = {
            filename,
            "syllabus": props.selectedSyllabusId
        }
        return new Promise((resolve, reject) => {
            APIService.getPreSignedUrlForAdditionalDocUpload(props.selectedSyllabusId, payload).then((response: any) => {
                resolve(response);
            }).catch((error: any) => {
                reject(error);
            });
        });
    }

    const handleFileDelete = (filename: string) => {
        return new Promise((resolve, reject) => {
            APIService.deleteAdditionalDoc(props.selectedSyllabusId, filename).then((response: any) => {
                resolve(response);
            }).catch((error: any) => {
                reject(error);
            });
        });
    }

    useEffect(() => {
        if (!_.isEqual(props.additionalDocuments, uploadedFileList)) {
            const docs = _.isArray(props.additionalDocuments) ? props.additionalDocuments : [];
            setUploadedFileList(docs);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.additionalDocuments]);


    useEffect(() => {
        setUploaderProps((prev) => ({ ...prev, fileList: uploadedFileList }))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadedFileList]);
    return (
        <Fragment>
            <Container fluid className='container-wrapper'>
                <div className='wrapper'>
                    <div>
                        <p className='theme-label centre-head'>Additional material</p>
                        <p className='syllabus-subtext'><span className='igcse-label'>Optional</span></p>
                    </div>
                </div>
            </Container>
            <Container className="candidate-list-container">
                <Card className="card-row">
                    <CardBody className="card-body pxy-2">
                        <Row className="align-items-center">
                            <Col md={8}>
                                <div className="pb-4"> <FontAwesomeIcon className="fileUpload" icon={faFolder} /><span className="pl-2 evidenceLabel"> Additional material </span></div>
                            </Col>
                        </Row>
                        <br></br>
                        <Row className="align-items-center">
                            <Col md={8}>
                                <Uploader {...uploaderProps}
                                    fileListWithStatus={props.additionalDocReadOnlyStatus}
                                    sourceLocation = 'additional'
                                    successCallback={handleUploadOrDeleteSuccess}
                                    getPreSignedUploadURl={returnPresignedUploadURl}
                                    errorCallback={handleUploadOrDeleteError}
                                    fileDeleteHandler={handleFileDelete} />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        </Fragment>
    )
}

const mapStateToProps = (state: any) => {
    return {
        additionalDocuments: state.setContextData?.candidateAdditionalDocuments,
        selectedSyllabusId: state.setContextData.selectedSyllabus?.syllabusNumber,
        additionalDocReadOnlyStatus: state.setContextData?.additionalDocReadOnlyStatus
    }
}

const mapDispatchToProps = {
    updateAdditionalDocument,
    toggleAlertShowStatus,
    fetchCandidateDocuments
}

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalMaterial);