import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder } from '@fortawesome/free-regular-svg-icons';
import _, { isEmpty } from 'lodash';
import Uploader from './../Shared/Uploader/uploader';
import { Constant } from './../../config/constant';
import {
    updateRationalDocument, toggleAlertShowStatus,
    fetchCandidateDocuments
} from '../../redux-building-blocks/actions';
import APIService from './../../services/apiService';

export const RationaleDocument = (props: any): JSX.Element => {
    const [uploadedFileList, setUploadedFileList] = useState<Array<string>>([]);
    const [uploaderProps, setUploaderProps] = useState({
        noOfMaxFile: 1,
        fileFormatAndSizeSupported: Constant.RATIONAL_DOCUMENT_ALLOWED_FILE_TYPE_SIZE
    });

    const [fileListWithStatus, setFileListWithStatus] = useState<Array<object>>([]);


    useEffect(() => {
        if (props.selectedSyllabusStatus?.trim() === Constant.SYLLABUS_STATUS_MESSAGE_FROM_API.SECOND_SAMPLE_REQUESTED?.trim()) {
            setUploaderProps({
                noOfMaxFile: 2,
                fileFormatAndSizeSupported: Constant.RATIONAL_DOCUMENT_ALLOWED_FILE_TYPE_SIZE
            })
            const statusArray = [{
                'file-name': props.candidateRationalDocuments?.rationaledocumentname,
                'file-type': props.candidateRationalDocuments?.rationaledocumentname?.split('.')[1],
                // 'read-only': (props.candidateRationalDocuments.rationaledocumentlocation === 'READ-ONLY')? true: false
                'read-only': true
            }]
            setFileListWithStatus(statusArray);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedSyllabusStatus, props.candidateRationalDocuments])

    const handleUploadOrDeleteSuccess = (currentRelatedFiles: Array<string>, type: string, fileName = '') => {
        let rationalFilename;
        setUploadedFileList(currentRelatedFiles);
        if (props.selectedSyllabusStatus === Constant.SYLLABUS_STATUS_MESSAGE_FROM_API.SECOND_SAMPLE_REQUESTED) {
            rationalFilename = (!_.isEmpty(currentRelatedFiles)) ? currentRelatedFiles[1] : 'NONE';
        } else {
            rationalFilename = (!_.isEmpty(currentRelatedFiles)) ? currentRelatedFiles[0] : 'NONE';
        }

        props.updateRationalDocument(rationalFilename);
        const message = (type === 'UPLOAD') ? `The file ${rationalFilename} will be scanned for Virus and only accepted if virus-free, else the file will be discarded.` :
            `${fileName} is deleted`;
        props.toggleAlertShowStatus({
            isShowAlert: true,
            alertColor: 'success',
            alertMessage: message
        });

        // Making this call to refresh file list as virus scan may discard uploaded file
        if (type === 'UPLOAD') {
            setTimeout(() => {
                props.fetchCandidateDocuments(props.selectedSyllabusId);
            }, Constant.GET_CALL_TIME_AFTER_UPLOAD);
        }

    }

    const handleUploadOrDeleteError = (currentRelatedFiles: Array<string>, type: string) => {
        const message = (type === 'UPLOAD') ? `You can't upload ${currentRelatedFiles}, Due to network issue` :
            `You can't delete ${currentRelatedFiles}, Due to network issue`;
        props.toggleAlertShowStatus({
            isShowAlert: true,
            alertColor: 'danger',
            alertMessage: message
        })
    }


    const returnPresignedUploadURl = (filename: string) => {
        const payload = {
            filename,
            "syllabus": props.selectedSyllabusId
        }
        return new Promise((resolve, reject) => {
            APIService.getPreSignedUrlForRationalDocUpload(props.selectedSyllabusId, payload).then((response: any) => {
                resolve(response);
            }).catch((error: any) => {
                reject(error);
            });
        });
    }

    const handleFileDelete = (filename: string) => {
        const upload = (props.selectedSyllabusStatus === Constant.SYLLABUS_STATUS_MESSAGE_FROM_API.SECOND_SAMPLE_REQUESTED) ? 2 : 1
        return new Promise((resolve, reject) => {
            APIService.deleteRationalDoc(props.selectedSyllabusId, filename, upload).then((response: any) => {
                resolve(response);
            }).catch((error: any) => {
                reject(error);
            });
        });
    }

    useEffect(() => {
        const activeUploadedFileList = _.reject(props.rationalDocuments,
            (eachFileName) => (isEmpty(eachFileName) ||
                _.lowerCase(eachFileName) === _.lowerCase('NONE') ||
                eachFileName.trim() === ""));

        if (!_.isEqual(activeUploadedFileList, uploadedFileList)) {
            setUploadedFileList(activeUploadedFileList);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.rationalDocuments]);

    useEffect(() => {
        setUploaderProps((prev) => ({ ...prev, fileList: uploadedFileList }))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadedFileList]);

    return (
        <Fragment>
            <Container fluid className='container-wrapper'>
                <div className='wrapper'>
                    <div>
                        <p className='theme-label centre-head'>Rationale Document</p>
                        <p className='syllabus-subtext'><span className='igcse-label'>Mandatory</span></p>
                    </div>
                </div>
            </Container>
            <Container className="candidate-list-container">
                <Card className="card-row">
                    <CardBody className="card-body pxy-2">
                        <Row className="align-items-center">
                            <Col md={8}>
                                <div className="pb-4"> <FontAwesomeIcon className="fileUpload" icon={faFolder} /><span className="pl-2 evidenceLabel"> Rationale Document </span></div>
                            </Col>
                        </Row>
                        <br></br>
                        <Row className="align-items-center">
                            <Col md={8}>
                                <Uploader {...uploaderProps} successCallback={handleUploadOrDeleteSuccess}
                                    fileListWithStatus={fileListWithStatus}
                                    sourceLocation='rational'
                                    getPreSignedUploadURl={returnPresignedUploadURl}
                                    errorCallback={handleUploadOrDeleteError}
                                    fileDeleteHandler={handleFileDelete} />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        </Fragment>
    )
}
const mapStateToProps = (state: any) => {
    return {
        rationalDocuments: [
            state.setContextData.candidateGenericDocuments?.rationaledocumentname,
            state.setContextData.candidateGenericDocuments?.secondrationaledocumentname
        ],
        selectedSyllabusId: state.setContextData.selectedSyllabus?.syllabusNumber,
        selectedSyllabusStatus: state.setContextData.selectedSyllabus?.syllabusStatus,
        candidateRationalDocuments: state.setContextData?.candidateGenericDocuments
    }
}

const mapDispatchToProps = {
    updateRationalDocument,
    toggleAlertShowStatus,
    fetchCandidateDocuments
}

export default connect(mapStateToProps, mapDispatchToProps)(RationaleDocument);