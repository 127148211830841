import { STSClient, AssumeRoleWithWebIdentityCommand } from "@aws-sdk/client-sts";
import jwtHelper from '../helper/jwt-helper';
import _ from 'lodash';

class AWSTokenHelperClass {
  // a client can be shared by difference commands.
  static awsSTSClient = new STSClient({ region: process.env.REACT_APP_AWS_REGION });
  static memoizedRunAWSTokenFetch = _.memoize(AWSTokenHelperClass.runAwsTokenFetch);
  static getAccessToken() {
    const currentToken = jwtHelper.getJwtToken() as string;
    return this.memoizedRunAWSTokenFetch(currentToken);
  }

  static runAwsTokenFetch(currentToken: string) {
    const params = {
      RoleArn: process.env.REACT_APP_AWS_ROLE_ARN_TO_GET_ACCESS_TOKEN,
      RoleSessionName: jwtHelper.getEncodedIdStringFromJWT() as string,
      WebIdentityToken: currentToken,
      DurationSeconds: 3600
    };
    const command = new AssumeRoleWithWebIdentityCommand(params);

    return this.awsSTSClient.send(command);
  }
  
}

export default AWSTokenHelperClass;

